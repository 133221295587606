import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

const TileSystemForSingle = ({
  firstCta,
  firstCtaLink,
  firstParagraph,
  firstParagraph2,
  firstTitle,
  fourthCta,
  fourthCtaLink,
  fourthParagraph,
  fourthParagraph2,
  mainTitle,
  fourthTitle,
  secondCta,
  secondCtaLink,
  secondParagraph,
  secondParagraph2,
  secondTitle,
  thirdCta,
  thirdCtaLink,
  thirdParagraph,
  thirdParagraph2,
  thirdTitle,
  firstImage,
  secondImage,
}) => {
  return (
    <div className="very-light-blue-background">
      <div className="eighty-spacer"></div>
      <div className="content-block tabs-activated">
        <div className="centered-content-container">
          <div className="centered-title-holder">
            <span>{mainTitle}</span>
          </div>
        </div>
        <div className="forty-spacer"></div>
        {secondTitle ?
        <Tabs className="tab-list">
          <TabList>
            <Tab><span>{firstTitle}</span></Tab>
            <Tab><span>{secondTitle}</span></Tab>
            <Tab><span>{thirdTitle}</span></Tab>
            <Tab><span>{fourthTitle}</span></Tab>
          </TabList>

          <div className="tabs">
            <TabPanel className="content tab">
              <h3>{firstTitle}</h3>
              <div className="one-flex-container">

                <div className="half-flex-container">
                <div>
                    {firstParagraph ? (
                      <span dangerouslySetInnerHTML={{ __html: firstParagraph }} />
                    ) : null}
                    {firstCtaLink ? (
                      <a href={firstCtaLink}>
                        <div className="featured-cta-link-holder">
                          <span>{firstCta} </span>
                        </div>
                      </a>
                    ) : null}
                    </div>
                </div>
                {firstParagraph2 ? (
                <div className="half-flex-container">
                  <div>
                    <span dangerouslySetInnerHTML={{ __html: firstParagraph2 }} />
                  </div>
                </div>
                ) : null}
              </div>
            </TabPanel>
            <TabPanel className="content tab">
              <h3>{secondTitle}</h3>
              <div className="one-flex-container">

                <div className="half-flex-container">
                  <div>
                  {secondParagraph ? (
                    <div dangerouslySetInnerHTML={{ __html: secondParagraph }} />
                    ) : null}
                    {secondCtaLink ? (
                      <a href={secondCtaLink}>
                        <div className="featured-cta-link-holder">
                          <span>{secondCta} </span>
                        </div>
                      </a>
                    ) : null}
                  </div>
                </div>

                {secondParagraph2 ? (
                <div className="half-flex-container">
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: secondParagraph2 }} />
                  </div>
                </div>
                ) : null}
              </div>
            </TabPanel>
            <TabPanel className="content tab">
              <h3>{thirdTitle}</h3>
              <div className="one-flex-container">

                <div className="half-flex-container">
                  <div>
                    {thirdParagraph ? (
                      <div dangerouslySetInnerHTML={{ __html: thirdParagraph }} />
                      ) : null}
                    {thirdCtaLink ? (
                      <a href={thirdCtaLink}>
                        <div className="featured-cta-link-holder">
                          <span>{thirdCta} </span>
                        </div>
                      </a>
                    ) : null}
                  </div>
                </div>

                {thirdParagraph2 ? (
                <div className="half-flex-container">
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: thirdParagraph2 }} />
                  </div>
                </div>
                ) : null}
              </div>
            </TabPanel>
            <TabPanel className="content tab">
              <h3>{fourthTitle}</h3>
              <div className="one-flex-container">

                <div className="half-flex-container">
                  <div>
                    {fourthParagraph ? (
                      <div dangerouslySetInnerHTML={{ __html: fourthParagraph }} />
                      ) : null}
                    {fourthCtaLink ? (
                      <a href={fourthCtaLink}>
                        <div className="featured-cta-link-holder">
                          <span>{fourthCta} </span>
                        </div>
                      </a>
                    ) : null}
                  </div>
                </div>

                {fourthParagraph2 ? (
                <div className="half-flex-container">
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: fourthParagraph2 }} />
                  </div>
                </div>
                  ) : null}
              </div>
            </TabPanel>
          </div>
        </Tabs>
        :
        <div className="tabs">
        <section id="tab-1" className="content tab tab-active">
        <h3>{firstTitle}</h3>
        <div className="one-flex-container">

          <div className="half-flex-container">
          <div>
          {firstParagraph ? (
              <span dangerouslySetInnerHTML={{ __html: firstParagraph }} />
              ) : null}
              {firstCtaLink ? (
                <a href={firstCtaLink}>
                  <div className="featured-cta-link-holder">
                    <span>{firstCta} </span>
                  </div>
                </a>
              ) : null}
              </div>
          </div>

          {firstParagraph2 ? (
          <div className="half-flex-container">
            <div>
              <span dangerouslySetInnerHTML={{ __html: firstParagraph2 }} />
            </div>
          </div>
          ) : null}
        </div></section>
        </div>
      }
      </div>
      <div className="eighty-spacer"></div>
      <div className="full-width-flex-container">
        {firstImage ? (
          <div className="full-half-flex-container">
            <img
              src={`/htbcontent/uploads/${firstImage.mediaDetails.file}`}
              alt={firstImage.altText}
              title={firstImage.title}
            ></img>
          </div>
        ) : null}
        {secondImage ? (
          <div className="full-half-flex-container">
            <img
              src={`/htbcontent/uploads/${secondImage.mediaDetails.file}`}
              alt={secondImage.altText}
              title={secondImage.title}
            ></img>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default TileSystemForSingle
